import Layout from '@/widgets/Layout/ui/Layout';
import { TrustedBy } from '@/widgets/TrustedBy';
import { AppHead } from '@/shared/ui/AppHead';
import { ContactUsSection } from '@/widgets/ContactUsSection';
import React from 'react';
import { ProductCards } from '@/widgets/ProductCards';

export default function Home() {
    return (
        <Layout>
            <AppHead
                title="All Data Feeds – Find data feed for your purposes"
                description="We carefully collect, prepare and deliver data feeds to our customers. We ensure data quality control by using our data feeds on a daily basis in our projects."
            />
            <div className="flex flex-col items-center gap-12 sm:gap-16 px-5 xl:px-0">
                <h1 className="max-w-3xl text-gray-800 bg-clip-text text-center text-4xl font-bold tracking-[-0.02em] md:text-6xl lg:text-8xl">
                    Find data feed for your purposes
                </h1>
                <p className="max-w-3xl text-center text-gray-500 md:text-xl">
                    We carefully collect, prepare and deliver data feeds to our
                    customers. We ensure data quality control by using our data
                    feeds on a daily basis in our projects.
                </p>
                <section>
                    <div className="text-center">
                        <dl className="grid gap-12 sm:gap-24 text-gray-800 sm:grid-cols-3">
                            <div className="flex flex-col items-center justify-center">
                                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                                    100+
                                </dt>
                                <dd className="text-gray-500 md:text-lg">
                                    data sources
                                </dd>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                                    1K+
                                </dt>
                                <dd className="text-gray-500 md:text-lg">
                                    customers
                                </dd>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                                    100K+
                                </dt>
                                <dd className="text-gray-500 md:text-lg">
                                    downloads
                                </dd>
                            </div>
                        </dl>
                    </div>
                </section>
                <ProductCards />
                <TrustedBy />
                <ContactUsSection />
            </div>
        </Layout>
    );
}
