import Image from 'next/image';

interface TrustedByProps {
    className?: string;
}

const TrustedBy = (props: TrustedByProps) => {
    return (
        <section>
            <div className="py-8 lg:py-16 -mb-8">
                <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    You’ll be in good company
                </h2>
                <div className="flex flex-wrap items-center justify-center gap-8 sm:gap-12 ">
                    <Image
                        src={'/trusted-by/flex.png'}
                        alt="Flex"
                        width="120"
                        height="50"
                    />
                    <Image
                        src={'/trusted-by/webafrica.png'}
                        alt="WebAfrica"
                        width="120"
                        height="38"
                    />
                    <Image
                        src={'/trusted-by/wework.png'}
                        alt="WeWork"
                        width="120"
                        height="26"
                    />
                    <Image
                        src={'/trusted-by/guarddog.png'}
                        alt="GuardDog"
                        width="120"
                        height="44"
                    />
                    <Image
                        src={'/trusted-by/cybercns.png'}
                        alt="CyberCNS"
                        width="120"
                        height="28"
                    />
                </div>
            </div>
        </section>
    );
};

export default TrustedBy;
