import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { Card } from '@/widgets/Card';

import React from 'react';
import { ButtonTheme } from '@/shared/ui/Button';
import { composeLink } from '@/shared/lib/links';

interface ProductCardsProps {
    className?: string;
}

const ProductCards = (props: ProductCardsProps) => {
    return (
        <section className="grid w-full max-w-screen-xl grid-cols-1 gap-8 lg:grid-cols-2">
            <Card
                logo="/products/tidf.svg"
                title="Threat Intelligence Data Feeds"
                description="Get actionable insights into the latest cyber threats, including malware, phishing, C2 (C&C), botnets, and other malicious activity."
                documentationLink={composeLink({
                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                    path: PATHS.DOCUMENTATION
                })}
                readMoreLink={composeLink({
                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE
                })}
            />
            <Card
                logo="/products/mac.svg"
                title="MAC Address Data Feed"
                description="Get the most comprehensive database of registered MAC address blocks, also referred to as OUIs (Organizationally Unique Identifiers)."
                documentationLink={composeLink({
                    subdomain: SUBDOMAINS.MAC_ADDRESS,
                    path: PATHS.DOCUMENTATION
                })}
                readMoreLink={composeLink({
                    subdomain: SUBDOMAINS.MAC_ADDRESS
                })}
            />
            <Card
                logo="/products/vpn.svg"
                title="VPN IP Ranges Data Feed"
                description="Our data feed provides you with a comprehensive list of IP ranges associated with various VPN providers. This allows you to quickly and easily identify and block VPN traffic on your network, ensuring that your systems are only accessed by legitimate users."
                comingSoon={true}
                contactUsLink="#contact-us"
                contactUsLinkText="Contact us for early access"
                buttonTheme={ButtonTheme.Outline}
            />
            <Card
                logo="/products/passwords.svg"
                title="Compromised Passwords Data Feed"
                description="Every year, millions of users fall victim to cybercrime due to weak passwords or reused credentials. In fact, recent studies show that 81% of data breaches are caused by stolen or weak passwords. But, with our Compromised Passwords Hashes Data Feed, you can proactively protect yourself and your organization from these threats."
                comingSoon={true}
                contactUsLink="#contact-us"
                contactUsLinkText="Contact us for early access"
                buttonTheme={ButtonTheme.Outline}
            />
        </section>
    );
};

export default ProductCards;
