import { Button, ButtonSize } from '@/shared/ui/Button';
import React from 'react';

interface ContactUsProps {
    className?: string;
    submitText?: string;
}

enum Products {
    THREAT_INTELLIGENCE = 'Threat Intelligence Data Feed',
    MAC_ADDRESS = 'MAC Address Data Feed',
    VPN_IP_RANGES_DATA_FEED = 'VPN IP Ranges Data Feed',
    COMPROMISED_PASSWORDS_DATA_FEED = 'Compromised Passwords Data Feed'
}

const ContactUsForm = (props: ContactUsProps) => {
    const { className, submitText = 'Leave request' } = props;

    const [fullName, setFullName] = React.useState('');
    const [position, setPosition] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [product, setProduct] = React.useState<Products>();
    const [message, setMessage] = React.useState('');

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    function resetForm() {
        setFullName('');
        setPosition('');
        setEmail('');
        setCompanyName('');
        setProduct(undefined);
        setMessage('');
    }

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsSubmitting(true);
        setIsError(false);
        fetch('/api/contact-us', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fullName,
                position,
                email,
                companyName,
                product,
                message
            })
        })
            .then(res => {
                if (res.ok) {
                    setIsSubmitted(true);
                    resetForm();
                } else {
                    setIsError(true);
                }
            })
            .catch(err => {
                setIsError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
                resetForm();
                setTimeout(() => {
                    setIsSubmitted(false);
                }, 5000);
            });
    }

    return (
        <form
            action="#"
            className="mx-auto w-full flex flex-col gap-6 max-w-xl w-full rounded-xl border border-gray-200 bg-white shadow-md p-4 sm:p-8"
            onSubmit={onSubmit}
        >
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="fullName"
                    className="text-sm font-medium text-gray-900"
                >
                    Full name
                </label>
                <input
                    type="text"
                    id="fullName"
                    className="rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-black focus:border-black"
                    required
                    value={fullName}
                    onChange={e => {
                        setFullName(e.target.value);
                    }}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="position"
                    className="text-sm font-medium text-gray-900"
                >
                    Position
                </label>
                <input
                    type="text"
                    id="position"
                    className="rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-black focus:border-black"
                    required
                    value={position}
                    onChange={e => {
                        setPosition(e.target.value);
                    }}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="email"
                    className="text-sm font-medium text-gray-900"
                >
                    Business email address
                </label>
                <input
                    type="email"
                    id="email"
                    className="rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-black focus:border-black"
                    required
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                    }}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="companyName"
                    className="text-sm font-medium text-gray-900"
                >
                    Company name
                </label>
                <input
                    type="text"
                    id="companyName"
                    className="rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-black focus:border-black"
                    required
                    value={companyName}
                    onChange={e => {
                        setCompanyName(e.target.value);
                    }}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="product"
                    className="text-sm font-medium text-gray-900"
                >
                    Product
                </label>
                <select
                    id="product"
                    required
                    className="rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-black focus:border-black"
                    value={product}
                    onChange={e => {
                        setProduct(e.target.value as Products);
                    }}
                >
                    <option disabled selected>
                        Choose a product
                    </option>
                    {Object.values(Products).map(product => (
                        <option key={product} value={product}>
                            {product}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="message"
                    className="text-sm font-medium text-gray-900"
                >
                    Message
                </label>
                <textarea
                    id="message"
                    rows={4}
                    className="rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-black focus:border-black"
                    required
                    value={message}
                    onChange={e => {
                        setMessage(e.target.value);
                    }}
                ></textarea>
            </div>
            <Button
                className="w-fit mx-auto"
                size={ButtonSize.Large}
                disabled={isSubmitting}
            >
                {submitText}
            </Button>
            {isError && (
                <div className="text-red-500 text-sm font-medium text-center">
                    Something went wrong. Please try again later or contact us.
                </div>
            )}
            {isSubmitted && (
                <div className="text-green-500 text-sm font-medium text-center">
                    Thank you for your request. We will contact you shortly.
                </div>
            )}
        </form>
    );
};

export default ContactUsForm;
