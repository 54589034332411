import React from 'react';

import { ContactUsForm } from '@/widgets/ContactUsForm';
import { TERMS_OF_SERVICE } from '@/shared/lib/texts';

interface ContactUsProps {
    className?: string;
}

const ContactUsSection = (props: ContactUsProps) => {
    return (
        <section id="contact-us">
            <div className="flex flex-col items-center gap-12 lg:gap-16 pt-8 lg:pt-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Contact Us
                </h2>
                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    Got a technical issue? Want to send feedback about data
                    feeds? Need details about our plans? Let us know.{' '}
                    {TERMS_OF_SERVICE}
                </p>
                <ContactUsForm />
            </div>
        </section>
    );
};

export default ContactUsSection;
