import styles from './Card.module.css';
import classNames from 'classnames';
import React from 'react';
import Image from 'next/image';
import { AppLink } from '@/shared/ui/AppLink';
import { Button, ButtonTheme } from '@/shared/ui/Button';

interface CardProps {
    className?: string;
    logo?: string;
    logos?: {
        src: string;
        alt: string;
        width?: number;
        height?: number;
    }[];
    title: string;
    description: string | React.ReactNode;
    documentationLink?: string;
    readMoreLink?: string;
    readMoreLinkText?: string;
    downloadLink?: string;
    downloadLinkText?: string;
    contactUsLink?: string;
    contactUsLinkText?: string;
    comingSoon?: boolean;
    buttonTheme?: ButtonTheme;
}

const Card = (props: CardProps) => {
    const {
        logo,
        logos,
        className,
        title,
        description,
        documentationLink,
        readMoreLink,
        readMoreLinkText,
        downloadLink,
        downloadLinkText,
        contactUsLink,
        contactUsLinkText,
        comingSoon,
        buttonTheme = ButtonTheme.Primary
    } = props;
    const classes = classNames(styles.Card, {}, [className]);

    return (
        <div
            className={classNames(
                classes,
                'relative flex flex-col gap-6 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md p-4 sm:p-8'
            )}
        >
            {logo && <Image src={logo} alt={title} width={40} height={40} />}
            {logos && (
                <div className="flex items-center gap-8 mb-2">
                    {logos.map((logo, index) => (
                        <Image
                            key={index}
                            src={logo.src}
                            alt={logo.alt}
                            width={40}
                            height={40}
                        />
                    ))}
                </div>
            )}
            <h3
                className={classNames(
                    'text-gray-700 text-xl font-bold',
                    'md:text-3xl md:font-normal'
                )}
            >
                {title}
            </h3>
            <div className="text-gray-600">{description}</div>
            {readMoreLink ||
            documentationLink ||
            downloadLink ||
            contactUsLink ? (
                <div className="flex items-center gap-4 sm:gap-8 mt-auto">
                    {readMoreLink && (
                        <AppLink href={readMoreLink} type="button">
                            {readMoreLinkText || 'Read More'}
                        </AppLink>
                    )}
                    {downloadLink && (
                        <a href={downloadLink} download>
                            <Button theme={buttonTheme}>
                                {downloadLinkText || 'Download'}
                            </Button>
                        </a>
                    )}
                    {contactUsLink && (
                        <a href={contactUsLink}>
                            <Button theme={buttonTheme}>
                                {contactUsLinkText || 'Contact Us'}
                            </Button>
                        </a>
                    )}
                    {documentationLink && (
                        <AppLink href={documentationLink}>
                            Documentation →
                        </AppLink>
                    )}
                </div>
            ) : null}
            {comingSoon && (
                <div className="absolute top-2 right-2 py-1.5 px-2 bg-gray-100 rounded-xl">
                    <div className="flex items-center justify-center w-full h-full text-gray-500 text-xs font-bold">
                        Coming soon
                    </div>
                </div>
            )}
        </div>
    );
};

export default Card;
